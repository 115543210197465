<template>
  <div>
    <!-- <div class="modal-header">
        <div class="modal-title">
            <span v-if="id">{{ l("Edit") }}</span>
            <span v-if="!id">{{ l("Create") }}</span>
        </div>
    </div> -->
    <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" :form="form">
      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-item :label="l('客户名称')">
            <a-input
              :disabled="isDisabled"
              :placeholder="l('请输入')"
              v-decorator="[
                'name',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('客户简称')">
            <a-input
              :disabled="isDisabled"
              :placeholder="l('请输入')"
              v-decorator="[
                'shortName',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('客户行业')">
            <a-input
              :disabled="isDisabled"
              :placeholder="l('请输入')"
              v-decorator="[
                'industry',
                {
                  rules: [
                    {
                      required: false,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('客户编码')">
            <a-input
              :disabled="isDisabled"
              :placeholder="l('请输入')"
              v-decorator="[
                'code',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('负责人')">
            <a-input
              :disabled="isDisabled"
              :placeholder="l('请输入')"
              v-decorator="[
                'headName',
                {
                  rules: [
                    {
                      required: false,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('手机号')">
            <a-input
              :disabled="isDisabled"
              :placeholder="l('请输入')"
              v-decorator="[
                'phone',
                {
                  rules: [
                    {
                      required: false,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('电子邮箱')">
            <a-input
              :disabled="isDisabled"
              :placeholder="l('请输入')"
              v-decorator="[
                'email',
                {
                  rules: [
                    {
                      required: false,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('座机号')">
            <a-input
              :disabled="isDisabled"
              :placeholder="l('请输入')"
              v-decorator="[
                'seatNumber',
                {
                  rules: [
                    {
                      required: false,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('传真号')">
            <a-input
              :disabled="isDisabled"
              :placeholder="l('请输入')"
              v-decorator="[
                'faxNumber',
                {
                  rules: [
                    {
                      required: false,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('通讯地址')">
            <a-input
              :disabled="isDisabled"
              :placeholder="l('请输入')"
              v-decorator="[
                'address',
                {
                  rules: [
                    {
                      required: false,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('邮政编码')">
            <a-input
              :disabled="isDisabled"
              :placeholder="l('请输入')"
              v-decorator="[
                'postalCode',
                {
                  rules: [
                    {
                      required: false,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <!-- <a-col :span="12">
          <a-form-item :label="l('纳税人识别号')">
            <a-input
              :placeholder="l('请输入')"
              v-decorator="[
                'taxpayerNumber',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col> -->
        <!-- <a-col :span="12">
          <a-form-item :label="l('开票类型')">
            <a-input
              :placeholder="l('请输入')"
              v-decorator="[
                'billingType',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col> -->
        <a-col :span="12">
          <a-form-item :label="l('备注')">
            <a-input
              :disabled="isDisabled"
              :placeholder="l('请输入')"
              v-decorator="[
                'remark',
                {
                  rules: [
                    {
                      required: false,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('附件资源')">
            <a-upload
              :action="uploadUrl"
              :multiple="true"
              :data="{ subdir: this.subdirName }"
              :file-list="fileList"
              :showUploadList="{
                showPreviewIcon: true,
                showRemoveIcon: !isDisabled,
              }"
              @change="handleUploadChange"
            >
              <a-button v-if="!isDisabled">
                <a-icon type="upload" /> 上传附件
              </a-button>
            </a-upload>
          </a-form-item>
        </a-col>
      </a-row>
      <!-- <a-col :span="24">
        <a-form-item class="btn--container">
          <a-button type="button" @click="close()">
            {{ l("Cancel") }}
          </a-button>
          <a-button type="primary" html-type="submit">
            {{ l("Save") }}
          </a-button>
        </a-form-item>
      </a-col> -->
    </a-form>
    <div class="modal-footer" v-if="!isDisabled">
      <a-button :disabled="saving" type="button" @click="close()">
        {{ l("Cancel") }}
      </a-button>
      <a-button :disabled="saving" type="primary" @click="handleSubmit">
        {{ l("Save") }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CompanyServiceProxy } from "./services/company-proxies";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";

export default {
  mixins: [ModalComponentBase],
  name: "create-or-edit-blogrolls",
  props: ["id", "type"],
  data() {
    return {
      // 获取到的数据
      entity: {},
      _serviceProxy: "",
      // 上传文件
      fileList: [],
      uploadUrl: "",
      subdirName: "",
      isDisabled: false,
    };
  },
  components: {},
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: "register",
    });
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this._serviceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
    this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
    this.subdirName = environment.production ? "abpProd" : "abpDev";
    if (this.id) {
      this.getData();
    }
    this.isDisabled = this.type === "preview" || this.type === "view";
  },
  mounted() {},
  methods: {
    // 上传组件 @change
    handleUploadChange(info) {
      let fileList = [...info.fileList];
      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
        }
        return file;
      });
      this.fileList = fileList;
      // console.log(this.fileList);
    },
    close() {
      this.$emit("close");
    },
    success(res) {
      this.$emit("success", res);
    },
    /**
     * 获取数据
     */
    async getData() {
      this.$emit("emitSpinning", "1");
      var res = await this._serviceProxy.getCustomerById(this.id);
      this.$emit("emitSpinning", "0");
      console.log(res);
      this.form.setFieldsValue({
        ...res,
        // id: this.id,
      });
      if (
        res.attachmenResources &&
        res.attachmenResources.indexOf(",") !== -1
      ) {
        let arr = res.attachmenResources.split(",");
        arr.map((item, i) => {
          let n = item.split("/");
          this.fileList.push({
            uid: `-${i + 1}`,
            url: `${AppConsts.uploadBaseUrl}${item}`,
            name: n[n.length - 1],
            response: { data: { Path: item } },
            status: "done",
          });
        });
      } else if (
        res.attachmenResources &&
        res.attachmenResources !== "undefined"
      ) {
        let n = res.attachmenResources.split("/");
        this.fileList.push({
          uid: `-1`,
          url: `${AppConsts.uploadBaseUrl}${res.attachmenResources}`,
          name: n[n.length - 1],
          response: { data: { Path: res.attachmenResources } },
          status: "done",
        });
      }
    },
    /**
     * 提交表单
     */
    async handleSubmit(e) {
      e.preventDefault();
      this.saving = true;
      let fileUrlList = [];
      this.fileList.map((item) => {
        fileUrlList.push(item.response.data.Path);
      });
      let attachmenResources =
        fileUrlList.length > 1 ? fileUrlList.join(",") : `${fileUrlList[0]}`;
      this.form.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          try {
            this.$emit("emitSpinning", "1");
            this.saving = false;

            let res = await this._serviceProxy.saveCustomer({
              ...values,
              id: this.id ? this.id : null,
              attachmenResources: attachmenResources,
            });
            this.$emit("emitSpinning", "0");
            this.$notification["success"]({
              message: this.l("SavedSuccessfully"),
            });
            this.success(true);
          } catch {
            this.$emit("emitSpinning", "0");
            this.saving = false;
          }
        } else {
          this.saving = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.btn--container .ant-form-item-children {
  display: block;
  // margin: 10px auto;
  text-align: right;
}

.modal-footer {
  padding: 10px 0;
  margin: 0;
}

.pleaseSelect-text {
  font-size: 14px;
  padding: 0 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
}
</style>
