<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <a-icon type="medicine-box" />
        <span v-if="customer.id"
          >{{ customer.type === "edit" ? "修改" : "查看"
          }}{{ customer.shortName }}</span
        >
        <span span v-if="!customer.id">创建客户信息</span>
      </div>
    </div>
    <!-- tab切换 -->
    <a-tabs defaultActiveKey="1" @change="tabChange">
      <!-- 角色名称 -->
      <a-tab-pane key="1">
        <span slot="tab">
          <a-icon type="medicine-box" />
          {{ l("基本信息") }}
        </span>
        <edit
          @close="close"
          @success="success"
          @emitSpinning="updateSpinning"
          :id="customer.id ? customer.id : null"
          :type="customer.type"
        ></edit>
      </a-tab-pane>

      <!-- 印章管理 -->
      <a-tab-pane
        key="2"
        forceRender
        v-if="customer && customer.id && customer.type === 'preview'"
      >
        <span slot="tab">
          <a-icon type="safety-certificate" />
          {{ l("印章管理") }}
        </span>
        <seal
          ref="seal"
          :req-key="'Customer'"
          :id="customer.id"
          :table-key="''"
        >
        </seal>
      </a-tab-pane>
      <!-- 银行账户 -->
      <a-tab-pane
        key="3"
        forceRender
        v-if="customer && customer.id && customer.type === 'preview'"
      >
        <span slot="tab">
          <a-icon type="setting" />
          {{ l("银行账户") }}
        </span>
        <bank-account
          ref="account"
          :req-key="'Customer'"
          :id="customer.id"
          :table-key="''"
        >
        </bank-account>
      </a-tab-pane>
      <!-- 发票管理 -->
      <a-tab-pane
        key="4"
        forceRender
        v-if="
          (customer && customer.id && customer.type === 'preview') ||
          customer.type === 'view'
        "
      >
        <span slot="tab">
          <a-icon type="pay-circle" />
          {{ l("发票抬头") }}
        </span>
        <customer-baseinvoice
          ref="baseinvoice"
          :req-url="baseInvoiceUrl"
          :id="customer.id"
          :type="customer.type"
          :table-key="'CustomerBaseInvoicePagedDto'"
        ></customer-baseinvoice>
      </a-tab-pane>
      <!-- 实名信息 -->
      <a-tab-pane
        key="5"
        forceRender
        v-if="customer && customer.id && customer.type === 'preview'"
      >
        <span slot="tab">
          <a-icon type="solution" />
          {{ l("实名信息") }}
        </span>
        <!-- <customer-realname-panel
          ref="realname"
          :req-url="realNameUrl"
          :id="customer.id"
          :type="customer.type"
          :table-key="'CustomerRealNamePagedDto'"
        ></customer-realname-panel> -->
        <real-name
          ref="realname"
          :req-key="'CustomerRealName'"
          :id="customer.id"
          :table-key="'CustomerRealNamePagedDto'"
        >
        </real-name>
      </a-tab-pane>
      <!-- 发放管理 -->
      <a-tab-pane
        key="6"
        forceRender
        v-if="false"
      >
        <span slot="tab">
          <a-icon type="file-protect" />
          {{ l("发放管理") }}
        </span>
        <provide
          ref="provide"
          :id="customer.id"
          :type="(type = customer.type)"
          :table-key="''"
        ></provide>
      </a-tab-pane>

      <a-tab-pane
        key="7"
        forceRender
        v-if="
          (customer && customer.id && customer.type === 'preview') ||
          customer.type === 'view'
        "
      >
        <span slot="tab">
          <a-icon type="file-protect" />
          用户授权
        </span>
        <customer-authed-user-list
          ref="authedUserList"
          @emitclose="close"
          :id="customer.id"
          :projectType="1"
          :isShow="true"
        ></customer-authed-user-list>
      </a-tab-pane>
    </a-tabs>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";
import Bus from "@/shared/bus/bus";
import Edit from "../edit";
import Seal from "@/components/seal";
import BankAccount from "@/components/bank-account";
import CustomerBaseinvoice from "@/components/customer-baseinvoice/customer-baseinvoice.vue";
// import RealNamePanel from "@/components/realname-panel/realname-panel";
import RealName from "@/components/real-name/real-name";
import Provide from "../provide/provide";
import customerAuthedUserList from "../customer-form/authed-user-list";
export default {
  name: "create-edit",
  mixins: [AppComponentBase, ModalComponentBase],
  data() {
    return {
      spinning: false,
      // 表单
      formLayout: "horizontal",
      // 选中的权限过滤
      selectedPermission: [],
      role: {},
      activeKey: -1,
      baseInvoiceUrl: {
        getPaged: "Customer/GetCustomerBaseInvoicePaged",
        save: "Customer/SaveCustomerBaseInvoice",
        getItem: "Customer/GetCustomerBaseInvoiceById",
        delete: "Customer/DeleteCustomerBaseInvoice",
      },
    };
  },
  components: {
    Edit,
    Seal,
    BankAccount,
    CustomerBaseinvoice,
    RealName,
    Provide,
    customerAuthedUserList,
  },
  created() {
    console.log(this);
    this.fullData(); // 模态框必须,填充数据到data字段
  },
  methods: {
    updateSpinning(newV) {
      if (newV === "0") {
        this.spinning = false;
      } else {
        this.spinning = true;
      }
    },
    tabChange(activeKey) {
      this.activeKey = activeKey;
      switch (activeKey) {
        case "1":
          // do sth...
          break;
        case "2":
          this.$refs.seal.getData(
            [{ key: "customerId", value: this.customer.id }],
            "/api/services/app/Customer/GetCustomerSealPaged"
          );
          break;
        case "3":
          this.$refs.account.getData(
            [{ key: "customerId", value: this.customer.id }],
            "/api/services/app/Customer/GetCustomerBankAccountPaged"
          );
          break;
        case "4":
          this.$refs.baseinvoice.getData(this.baseInvoiceUrl.getPaged);
          break;
        case "5":
          this.$refs.realname.getData(
            [{ key: "customerId", value: this.customer.id }],
            "/api/services/app/CustomerRealName/GetPaged"
          );
          break;
        case "6":
          this.$refs.provide.getData();
          break;
        case "7":
          this.$refs.authedUserList._getAuthedUserList();
          break;
      }
    },
  },
};
</script>

<style scoped lang="less">
.modal-header {
  margin-bottom: 0;
  .anticon {
    margin-right: 10px;
  }
}
.pagination {
  margin: 10px auto;
  text-align: right;
}
</style>
