import "rxjs/add/operator/finally";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { PagedResultDtoOfCompanyListDto, CompanyListDto } from "./model";
import { FileDto, SwaggerException } from "@/shared/service-proxies/model";
export class CompanyServiceProxy {
  private instance: AxiosInstance;
  private baseUrl: string;
  protected jsonParseReviver:
    | ((key: string, value: any) => any)
    | undefined = undefined;

  constructor(baseUrl?: string, instance?: AxiosInstance) {
    this.instance = instance ? instance : axios.create();
    this.baseUrl = baseUrl ? baseUrl : "";
  }

  /**
   * 获取单条详情
   * @param body (optional)
   * @return Success
   */
  async getForEdit(id: string | undefined): Promise<CompanyListDto> {
    let url_ = this.baseUrl + "/api/services/app/Company/GetEditById?id=" + id;
    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    const _response = await this.instance.request(options_);
    return this.processGetForEdit(_response);
  }

  protected processGetForEdit(
    response: AxiosResponse
  ): Promise<CompanyListDto> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      let result200: any = null;
      let resultData200 = _responseText;
      result200 = CompanyListDto.fromJS(resultData200);
      return result200;
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<CompanyListDto>(<any>null);
  }

  /**
   * 添加或者修改文章的公共方法
   * @param body (optional)
   * @return Success
   */
  async createOrUpdate(body: CompanyListDto | undefined): Promise<any> {
    let url_ = this.baseUrl + "/api/services/app/Company/SaveCompany";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(body);

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "POST",
      url: url_,
      headers: {
        "Content-Type": "application/json-patch+json",
      },
    };
    var res = await this.instance.request(options_);
    var fnc = await this.processCreateOrUpdate(res);

    return fnc;
  }

  protected processCreateOrUpdate(response: AxiosResponse): Promise<any> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      return Promise.resolve<any>(<any>response);
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<any>(<any>response);
  }

  /**
   * 获取博客的分页列表信息
   * @param filterText (optional)
   * @param sorting (optional)
   * @param maxResultCount (optional)
   * @param skipCount (optional)
   * @return Success
   */
  async getPaged(
    filterText: string | undefined,
    sorting: string | undefined,
    maxResultCount: number | undefined,
    skipCount: number | undefined
  ): Promise<any> {
    let url_ = this.baseUrl + "/api/services/app/Company/GetPaged?";
    if (filterText === null) {
      throw new Error("The parameter 'filterText' cannot be null.");
    } else if (filterText !== undefined) {
      url_ += "filterText=" + encodeURIComponent("" + filterText) + "&";
    }
    if (sorting === null) {
      throw new Error("The parameter 'sorting' cannot be null.");
    } else if (sorting !== undefined) {
      url_ += "sorting=" + encodeURIComponent("" + sorting) + "&";
    }
    if (maxResultCount === null) {
      throw new Error("The parameter 'maxResultCount' cannot be null.");
    } else if (maxResultCount !== undefined) {
      url_ += "maxResultCount=" + encodeURIComponent("" + maxResultCount) + "&";
    }
    if (skipCount === null) {
      throw new Error("The parameter 'skipCount' cannot be null.");
    } else if (skipCount !== undefined) {
      url_ += "skipCount=" + encodeURIComponent("" + skipCount) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    const _response = await this.instance.request(options_);
    return this.processGetPaged(_response);
  }

  protected processGetPaged(
    response: AxiosResponse
  ): Promise<any> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      let result200: any = null;
      let resultData200 = _responseText;
      // result200 = PagedResultDtoOfCompanyListDto.fromJS(resultData200);
      return resultData200;
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<any>(<any>null);
  }

  /**
   * 导出博客为excel文件
   * @return Success
   */
  async getToExcelFile(): Promise<FileDto> {
    let url_ = this.baseUrl + "/api/services/app/Customer/GetToExcelFile";
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    const _response = await this.instance.request(options_);
    return this.processGetToExcelFile(_response);
  }

  protected processGetToExcelFile(response: AxiosResponse): Promise<FileDto> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      let result200: any = null;
      let resultData200 = _responseText;
      result200 = FileDto.fromJS(resultData200);
      return result200;
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<FileDto>(<any>null);
  }



  /**
   * 删除信息
   * @param id (optional)
   * @return Success
   */
  delete(id: string | undefined): Promise<void> {
    let url_ = this.baseUrl + "/api/services/app/Company/DeleteCompany?";
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      url_ += "id=" + encodeURIComponent("" + id) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "DELETE",
      url: url_,
      headers: {},
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processDelete(_response);
    });
  }

  protected processDelete(response: AxiosResponse): Promise<void> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      return Promise.resolve<void>(<any>null);
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<void>(<any>null);
  }

  /**
   * 获取客户扩展信息(分页)
   * @param name 
   * @param filterText 
   * @param sorting 
   * @param maxResultCount 
   * @param skipCount 
   */
  async getCustomerPaged(
    name: string | undefined,
    filterText: string | undefined,
    sorting: string | undefined,
    maxResultCount: string | undefined,
    skipCount: string | undefined,
  ): Promise<any> {
    let url_ = this.baseUrl + `/api/services/app/Customer/GetCustomerPaged?name=${name}&filterText=${filterText}&sorting=${sorting}&maxResultCount=${maxResultCount}&skipCount=${skipCount}`;
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    const _response = await this.instance.request(options_);
    return this.processGetPaged(_response);
  }

  /**
   * 获取单条客户扩展信息
   * @param id 
   */
  async getCustomerById(id: string | undefined): Promise<any> {
    let url_ = this.baseUrl + "/api/services/app/Customer/GetCustomerById?";
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      url_ += "id=" + encodeURIComponent("" + id) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    const _response = await this.instance.request(options_);
    return this.processGetItemById(_response);

  }
  protected processGetItemById(response: AxiosResponse): Promise<any> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      return _responseText;
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<any>(<any>null);
  }

  /**
   * 新增或编辑客户扩展信息
   * @param body 
   */
  async saveCustomer(body: any | undefined): Promise<any> {
    let url_ = this.baseUrl + `/api/services/app/Customer/SaveCustomer`;
    url_ = url_.replace(/[?&]$/, "");

    const content_ = body;

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "POST",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };

    const _response = await this.instance.request(options_);
    return this.processSave(_response);
  }
  protected processSave(response: AxiosResponse): Promise<any> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      return _responseText
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException("An unexpected server error occurred.", status, _responseText, _headers);
    }
    return Promise.resolve<void>(<any>null);
  }

  /**
   * 删除客户扩展信息
   * @param id 
   */
  async deleteCustomer(id: string | undefined): Promise<void> {
    let url_ = this.baseUrl + "/api/services/app/Customer/DeleteCustomer?";
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      url_ += "id=" + encodeURIComponent("" + id) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "DELETE",
      url: url_,
      headers: {},
    };

    const _response = await this.instance.request(options_);
    return this.processDelete(_response);
  }





}

function throwException(
  message: string,
  status: number,
  response: string,
  headers: { [key: string]: any },
  result?: any
): any {
  if (result !== null && result !== undefined) {
    throw result;
  } else {
    throw new SwaggerException(message, status, response, headers, null);
  }
}
