import moment from "moment";
export interface IPagedResultDtoOfBlogListDto {
  totalCount: number;
  items: CompanyListDto[] | undefined;
}

/** 博客的编辑DTO LTMCompanyName.YoyoCmsTemplate.Modules.Blogging.Blogs.Blog */
export class CompanyListDto implements ICompanyListDto {
  /** 博客名称 */
  name: string | undefined;
  /** 博客短名称 */
  shortName: string | undefined;
  /** 编号 */
  no: string | undefined;
  /** 行业 */
  industry: number | undefined;
  /** 联系号码 */
  mobile: string | undefined;
  /** 邮箱 */
  email: string | undefined;
  /** 传真 */
  fax: string | undefined;
  /** 地址 */
  address: string | undefined;
  /** 开户行 */
  openBankAndCard: string | undefined;
  /** 邮编 */
  postcode: string | undefined;
  /** 备注 */
  remark: string | undefined;
  isDeleted: boolean;
  deleterUserId: number | undefined;
  deletionTime: moment.Moment | undefined;
  lastModificationTime: moment.Moment | undefined;
  lastModifierUserId: number | undefined;
  creationTime: moment.Moment;
  creatorUserId: number | undefined;
  id: string;

  constructor(data?: CompanyListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.name = _data["name"];
      this.shortName = _data["shortName"];
      this.no = _data["no"];
      this.industry = _data["industry"];
      this.mobile = _data["mobile"];
      this.email = _data["email"];
      this.fax = _data["fax"];
      this.address = _data["address"];
      this.openBankAndCard = _data["openBankAndCard"];
      this.postcode = _data["postcode"];
      this.remark = _data["remark"];

      this.isDeleted = _data["isDeleted"];
      this.deleterUserId = _data["deleterUserId"];
      this.deletionTime = _data["deletionTime"]
        ? moment(_data["deletionTime"].toString())
        : <any>undefined;
      this.lastModificationTime = _data["lastModificationTime"]
        ? moment(_data["lastModificationTime"].toString())
        : <any>undefined;
      this.lastModifierUserId = _data["lastModifierUserId"];
      this.creationTime = _data["creationTime"]
        ? moment(_data["creationTime"].toString())
        : <any>undefined;
      this.creatorUserId = _data["creatorUserId"];
      this.id = _data["id"];
    }
  }

  static fromJS(data: any): CompanyListDto {
    data = typeof data === "object" ? data : {};
    let result = new CompanyListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === "object" ? data : {};
    data["name"] = this.name;
    data["shortName"] = this.shortName;
    data["no"] = this.no;
    data["industry"] = this.industry;
    data["mobile"] = this.mobile;
    data["email"] = this.email;
    data["fax"] = this.fax;
    data["address"] = this.address;
    data["openBankAndCard"] = this.openBankAndCard;
    data["postcode"] = this.postcode;
    data["remark"] = this.remark;

    data["isDeleted"] = this.isDeleted;
    data["deleterUserId"] = this.deleterUserId;
    data["deletionTime"] = this.deletionTime
      ? this.deletionTime.toISOString()
      : <any>undefined;
    data["lastModificationTime"] = this.lastModificationTime
      ? this.lastModificationTime.toISOString()
      : <any>undefined;
    data["lastModifierUserId"] = this.lastModifierUserId;
    data["creationTime"] = this.creationTime
      ? this.creationTime.toISOString()
      : <any>undefined;
    data["creatorUserId"] = this.creatorUserId;
    data["id"] = this.id;
    return data;
  }

  clone(): CompanyListDto {
    const json = this.toJSON();
    let result = new CompanyListDto();
    result.init(json);
    return result;
  }
}

/** 博客的编辑DTO LTMCompanyName.YoyoCmsTemplate.Modules.Blogging.Blogs.Blog */
export interface ICompanyListDto {
  /** 博客名称 */
  name: string | undefined;
  /** 博客短名称 */
  shortName: string | undefined;
  /** 编号 */
  no: string | undefined;
  /** 行业 */
  industry: number | undefined;
  /** 联系号码 */
  mobile: string | undefined;
  /** 邮箱 */
  email: string | undefined;
  /** 传真 */
  fax: string | undefined;
  /** 地址 */
  address: string | undefined;
  /** 开户行 */
  openBankAndCard: string | undefined;
  /** 邮编 */
  postcode: string | undefined;
  /** 备注 */
  remark: string | undefined;
  isDeleted: boolean;
  deleterUserId: number | undefined;
  deletionTime: moment.Moment | undefined;
  lastModificationTime: moment.Moment | undefined;
  lastModifierUserId: number | undefined;
  creationTime: moment.Moment;
  creatorUserId: number | undefined;
  id: string;
}

export class PagedResultDtoOfCompanyListDto
  implements IPagedResultDtoOfCompanyListDto {
  totalCount: number;
  items: CompanyListDto[] | undefined;

  constructor(data?: IPagedResultDtoOfCompanyListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.totalCount = _data["totalCount"];
      if (Array.isArray(_data["items"])) {
        this.items = [] as any;
        for (let item of _data["items"]) {
          this.items.push(CompanyListDto.fromJS(item));
        }
      }
    }
  }

  static fromJS(data: any): PagedResultDtoOfCompanyListDto {
    data = typeof data === "object" ? data : {};
    let result = new PagedResultDtoOfCompanyListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === "object" ? data : {};
    data["totalCount"] = this.totalCount;
    if (Array.isArray(this.items)) {
      data["items"] = [];
      for (let item of this.items) {
        data["items"].push(item.toJSON());
      }
    }
    return data;
  }

  clone(): PagedResultDtoOfCompanyListDto {
    const json = this.toJSON();
    let result = new PagedResultDtoOfCompanyListDto();
    result.init(json);
    return result;
  }
}

export interface IPagedResultDtoOfCompanyListDto {
  totalCount: number;
  items: CompanyListDto[] | undefined;
}
