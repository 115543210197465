<template>
  <a-spin :spinning="spinning">
    <section>
      <div class="modal-header">
        <div class="modal-title" v-if="pageType === 'edit'">
          <a-icon type="share-alt" />{{ l("Edit") }}:
          {{ organizationUnit.name }}
        </div>
        <div class="modal-title" v-else>
          <a-icon type="share-alt" />{{
            pageType === "create" ? "创建" : "查看"
          }}：发票
        </div>
      </div>
      <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-row :gutter="24" class="form-wrapper" v-if="false">
          <a-col :span="12">
            <a-form-item label="发票类型">
              <a-select
                placeholder="请输入发票类型"
                allowClear
                :disabled="isDisabled"
                v-decorator="[
                  'type',
                  {
                    rules: [
                      {
                        required: true,
                        message: l('validation.required'),
                      },
                    ],
                  },
                ]"
              >
                <a-select-option key="0" :value="2"
                  >增值税专用发票</a-select-option
                >
                <a-select-option key="1" :value="1"
                  >增值税普通发票</a-select-option
                >
                <!-- <a-select-option v-for="(item,index) in typeList" :key="index" :value="item.code" >{{ item.text }}</a-select-option> -->
              </a-select>
            </a-form-item>
          </a-col>
          <!-- <a-col :span="12"> </a-col> -->
        </a-row>
        <a-row :gutter="24" class="form-wrapper">
          <a-col :span="12">
            <a-form-item label="发票抬头">
              <a-input
                placeholder="请输入发票抬头"
                allowClear
                :disabled="isDisabled"
                v-decorator="[
                  'title',
                  {
                    rules: [
                      { required: true, message: l('ThisFieldIsRequired') },
                      { max: 128, message: l('MaxLength') },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="纳税人识别码">
              <a-input
                placeholder="请输入纳税人识别码"
                allowClear
                :disabled="isDisabled"
                v-decorator="[
                  'number',
                  {
                    rules: [
                      { required: true, message: l('ThisFieldIsRequired') },
                      { max: 128, message: l('MaxLength') },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24" class="form-wrapper">
          <a-col :span="12">
            <a-form-item label="基本开户银行">
              <a-input
                placeholder="请输入基本开户银行"
                allowClear
                :disabled="isDisabled"
                v-decorator="[
                  'bankName',
                  {
                    rules: [
                      { required: true, message: l('ThisFieldIsRequired') },
                      { max: 128, message: l('MaxLength') },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="基本开户账号">
              <a-input
                placeholder="请输入基本开户账号"
                allowClear
                :disabled="isDisabled"
                v-decorator="[
                  'bankAccount',
                  {
                    rules: [
                      { required: true, message: l('ThisFieldIsRequired') },
                      { max: 128, message: l('MaxLength') },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24" class="form-wrapper">
          <a-col :span="12">
            <a-form-item label="地址">
              <a-input
                placeholder="请输入地址"
                allowClear
                :disabled="isDisabled"
                v-decorator="[
                  'address',
                  {
                    rules: [
                      { required: true, message: l('ThisFieldIsRequired') },
                      { max: 128, message: l('MaxLength') },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="电话">
              <a-input
                placeholder="请输入电话"
                allowClear
                :disabled="isDisabled"
                v-decorator="[
                  'telephone',
                  {
                    rules: [
                      { required: true, message: l('ThisFieldIsRequired') },
                      { max: 128, message: l('MaxLength') },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <div class="modal-footer" v-if="pageType !== 'preview'">
        <a-button :disabled="saving" @click="close()" type="button">
          <a-icon type="close-circle" />
          {{ l("Cancel") }}
        </a-button>
        <a-button :loading="saving" :type="'primary'" @click="handleSubmit()">
          <a-icon type="save" />
          {{ l("Save") }}
        </a-button>
      </div>
    </section>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";
import { CustomerBaseInvoiceServiceProxy } from "../services/customer-baseinvoice-proxies";
import { environment } from "@/environments/environment";

export default {
  name: "operate-customer-baseinvoice",
  mixins: [AppComponentBase, ModalComponentBase],
  data() {
    return {
      spinning: false,
      // 表单
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      _serviceProxy: null,
      formDate: null,
      pageType: "", // preview,edit,create
      isDisabled: false,
      switchVal: false,
      typeList: [
        { code: 1, text: "增值税普通发票" },
        { code: 2, text: "增值税专用发票" },
      ],
    };
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this._serviceProxy = new CustomerBaseInvoiceServiceProxy(
      this.$apiUrl,
      this.$api
    );
  },
  mounted() {
    // 修改
    if (this.organizationUnit.id) {
      this.$nextTick(() => {
        this._getAccountById(
          this.organizationUnit.id,
          this.organizationUnit.reqUrl.getItem
        );
      });
    }
  },
  methods: {
    async _getAccountById(id, url) {
      this.spinning = true;
      let res = await this._serviceProxy.getAccountById(id, url);
      // res.type = ["增值税普通发票","增值税专用发票"][res.type];
      this.form.setFieldsValue({
        ...res,
      });
      this.switchVal = this.organizationUnit.isDefault === true;
      this.pageType = this.organizationUnit.type;
      this.isDisabled = this.organizationUnit.type === "preview";
      this.spinning = false;
    },
    // 提交表单
    handleSubmit() {
      this.form.validateFields((err, values) => {
        console.log(values);
        if (!err) {
          console.log("Received values of form: ", values);
          this.createEditUnit(values);
        }
      });
    },
    // 创建
    async createEditUnit(params) {
      //   Object.assign(params, {
      //     parentId: this.organizationUnit.parentId,
      //   });
      this.saving = true;
      try {
        let res = await this._serviceProxy.saveAccount(
          {
            ...params,
            customerId: this.organizationUnit.parentId,
            organizationUnitId: this.organizationUnit.parentId,
            id: this.organizationUnit.id ? this.organizationUnit.id : null,
            isDefault: this.switchVal,
          },
          this.organizationUnit.reqUrl.save
        );
        this.saving = false;
        this.$notification["success"]({
          message: this.l("SavedSuccessfully"),
        });
        this.success(res);
      } catch (error) {
        this.saving = false;
      }
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../styles/common.less";

.modal-header {
  .anticon-share-alt {
    margin-right: 10px;
  }
}
.form-wrapper {
  .ant-form-item {
    margin-bottom: 14px;
  }
  .preview-list {
    margin-bottom: 0;
    padding: 0;
    li {
      list-style: none;
      line-height: 30px;
    }
  }
}
</style>
