<template>
  <a-spin :spinning="spinning">
    <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :actions-type="actionsType"
      :columns="columns"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <a-row :gutter="8" type="flex" align="middle">
        <a-col :span="8">
          <a-input-search
            name="filterText"
            @search="getData()"
            :placeholder="l('SearchWithThreeDot')"
            enterButton
            v-model="filterText"
          />
        </a-col>
        <a-col :span="16" style="text-align: right">
          <a @click="createOrEdit(null, 'create')">
            <a-icon type="plus" /> {{ l("添加发放") }}
          </a>
          <a-divider type="vertical"></a-divider>
          <!-- <a @click="exportExcel">
            <a-icon type="file-excel" /> {{ l("Export") }}
          </a> -->
          <a-divider type="vertical"></a-divider>
          <a :title="l('Refresh')" @click="clearFilterAndRefresh()">
            <a-icon type="reload"
          /></a>
        </a-col>
      </a-row>
    </react-table>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import Bus from "@/shared/bus/bus";
import ReactTable from "@/components/react-table";
import ReactModify from "@/components/react-modify";
import { CommonServiceProxy } from "@/shared/common-service";
import { fileDownloadService } from "@/shared/utils";

export default {
  name: "provide",
  mixins: [AppComponentBase],
  props: ["id", "tableKey"],
  components: { ReactTable },
  data() {
    return {
      spinning: false,
      commonService: null,
      selectedRowKeys: [],
      name: "",
      filterText: "",
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 30, skipCount: 0 },
      // 用户表格
      columns: [
        {
          title: this.l("名称"),
          dataIndex: "name",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "name" },
        },
        {
          title: this.l("客户名称"),
          dataIndex: "customerName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "customerName" },
        },
        {
          title: this.l("是否默认"),
          dataIndex: "isDefault",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "isDefault" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: false,
          align: "center",
          fixed: "right",
          scopedSlots: { customRender: "actions" },
        },
      ],
      // 用户数据
      tableData: [],
      // btns
      actionsType: {},
      // 保存页面参数中介
      getDataParams: [],
    };
  },
  computed: {},
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initActionsType();
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    // 拉取数据
    async getData(opt) {
      this.spinning = true;
      let params = {
        customerId: this.id,
        filterText: this.filterText,
        sorting: this.request.sorting,
        skipCount: this.request.skipCount,
        maxResultCount: this.request.maxResultCount,
      };
      if (opt && opt.length) {
        if (!this.getDataParams.length) this.getDataParams = opt;
        opt.map((it) => {
          if (it.key && it.value) {
            params[it.key] = it.value;
          }
        });
      } else if (this.getDataParams && this.getDataParams.length) {
        this.getDataParams.map((it) => {
          if (it.key && it.value) {
            params[it.key] = it.value;
          }
        });
      }
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/CustomerProvideConfig/GetPaged",
          params: params,
        });
        this.spinning = false;
        this.tableData = res.items.map((o) => {
          return {
            ...o,
            isDefault: o.isDefault ? "是" : "否",
          };
        });
        this.totalItems = res.totalCount;
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
        this.totalPages = Math.ceil(
          res.totalCount / this.request.maxResultCount
        );
      } catch (error) {
        this.spinning = false;
      }
    },
    // 初始化需要显示的func btn
    initActionsType() {
      let _this = this;
      let obj = {
        type: "provide",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: true,
              name: this.l("Edit"),
              icon: "edit",
              fn: (data) => {
                _this.createOrEdit(data, "edit");
                // _this.createOrEdit(newV.item, 'preview');
                // _this.createOrEdit(newV.item, 'create');
              },
            },
            {
              granted: true,
              name: this.l("设为默认"),
              icon: "profile",
              fn: (data) => {
                _this.changeDefault(data);
              },
            },
          ],
          delete: {
            granted: true,
            name: this.l("Delete"),
            fn: (data) => {
              _this.deleteItem(data.id);
            },
          },
        },
      };
      this.actionsType = obj;
    },
    // 删除
    async deleteItem(id) {
      // let { id } = newV.item;
      this.spinning = true;
      try {
        let res = await this.commonService.delete({
          url:
            "/api/services/app/CustomerProvideConfig/DeleteCustomerProvideConfig",
          params: id,
        });
        this.spinning = false;
        this.getData();
        this.$notification["success"]({
          message: this.l("SuccessfullyRemoved"),
        });
        this.clearFilterAndRefresh();
      } catch (error) {
        this.spinning = false;
      }
    },
    changeDefault(item) {
      // let { item } = newV
      let _this = this;
      this.$confirm({
        title: "默认设置",
        content: "此项设为默认?",
        okText: "确定",
        cancelText: "取消",
        async onOk() {
          // setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          try {
            let res = await _this.commonService.post({
              url: "/api/services/app/CustomerProvideConfig/ChangeDefault",
              params: { id: item.id },
            });
            _this.getData();
          } catch (error) {}
        },
        onCancel() {},
      });
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.request = { sorting: "", maxResultCount: 30, skipCount: 0 };
      this.name = "";
      this.filterText = "";
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    // 导出
    async exportExcel() {
      this.spinning = true;
      try {
        const res = await this.commonService.get({
          url: "/api/services/app/CustomerProvideConfig/GetToExcelFile",
          params: {},
        });
        fileDownloadService.downloadTempFile(result);
      } catch (error) {
        this.spinning = false;
      }
    },
    // 增加修改查看
    createOrEdit(item, type) {
      const list = [
        {
          label: "appId",
          value: "appId",
          type: "commonInput",
          disabled: false,
          required: true,
          rules: [],
          changeFn: () => {},
        },
        {
          label: "privateKey",
          value: "privateKey",
          type: "textarea",
          disabled: false,
          required: true,
          rules: [],
          changeFn: () => {},
        },
        {
          label: "名称",
          value: "name",
          type: "commonInput",
          disabled: false,
          required: true,
          rules: [],
          changeFn: () => {},
        },
      ];
      let reqParams = [];
      if (item && item.id) reqParams.push({ key: "id", value: item.id });
      let otherPrams = {};
      ModalHelper.create(
        ReactModify,
        {
          params: {
            type: type, // edit,preview,create
            data: list,
            reqParams: [...reqParams, { key: "customerId", value: this.id }],
            reqUrl: {
              getById: "/api/services/app/CustomerProvideConfig/GetEditById",
              save:
                "/api/services/app/CustomerProvideConfig/SaveCustomerProvideConfig",
            },
            otherParams: {
              isDefault: this.tableData.length,
            },
            getItemfn: async (res, form, options) => {
              form.setFieldsValue({ ...res });
              if (options) {
                // ...
              }
            },
            submitfn: async (values, queryParams) => {
              let obj = {};
              queryParams.reqParams.map((item) => {
                obj[item.key] = item.value;
              });
              Object.assign(values, obj);
              // console.log(values)
              try {
                let res = await this.commonService.post({
                  url: queryParams.reqUrl.save,
                  params: {
                    ...values,
                    isDefault: queryParams.otherParams.isDefault ? false : true,
                  },
                });
                return true;
              } catch (error) {
                return false;
              }
            },
          },
        },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.opeattion-container {
  margin: 20px 0;
}
.pagination {
  margin: 10px auto;
  text-align: right;
}
</style>
