var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('section',[_c('div',{staticClass:"modal-header"},[(_vm.pageType === 'edit')?_c('div',{staticClass:"modal-title"},[_c('a-icon',{attrs:{"type":"share-alt"}}),_vm._v(_vm._s(_vm.l("Edit"))+": "+_vm._s(_vm.organizationUnit.name)+" ")],1):_c('div',{staticClass:"modal-title"},[_c('a-icon',{attrs:{"type":"share-alt"}}),_vm._v(_vm._s(_vm.pageType === "create" ? "创建" : "查看")+"：发票 ")],1)]),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 18 }}},[(false)?_c('a-row',{staticClass:"form-wrapper",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"发票类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'type',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.l('validation.required'),
                    } ],
                } ]),expression:"[\n                'type',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: l('validation.required'),\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"请输入发票类型","allowClear":"","disabled":_vm.isDisabled}},[_c('a-select-option',{key:"0",attrs:{"value":2}},[_vm._v("增值税专用发票")]),_c('a-select-option',{key:"1",attrs:{"value":1}},[_vm._v("增值税普通发票")])],1)],1)],1)],1):_vm._e(),_c('a-row',{staticClass:"form-wrapper",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"发票抬头"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'title',
                {
                  rules: [
                    { required: true, message: _vm.l('ThisFieldIsRequired') },
                    { max: 128, message: _vm.l('MaxLength') } ],
                } ]),expression:"[\n                'title',\n                {\n                  rules: [\n                    { required: true, message: l('ThisFieldIsRequired') },\n                    { max: 128, message: l('MaxLength') },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"请输入发票抬头","allowClear":"","disabled":_vm.isDisabled}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"纳税人识别码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'number',
                {
                  rules: [
                    { required: true, message: _vm.l('ThisFieldIsRequired') },
                    { max: 128, message: _vm.l('MaxLength') } ],
                } ]),expression:"[\n                'number',\n                {\n                  rules: [\n                    { required: true, message: l('ThisFieldIsRequired') },\n                    { max: 128, message: l('MaxLength') },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"请输入纳税人识别码","allowClear":"","disabled":_vm.isDisabled}})],1)],1)],1),_c('a-row',{staticClass:"form-wrapper",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"基本开户银行"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'bankName',
                {
                  rules: [
                    { required: true, message: _vm.l('ThisFieldIsRequired') },
                    { max: 128, message: _vm.l('MaxLength') } ],
                } ]),expression:"[\n                'bankName',\n                {\n                  rules: [\n                    { required: true, message: l('ThisFieldIsRequired') },\n                    { max: 128, message: l('MaxLength') },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"请输入基本开户银行","allowClear":"","disabled":_vm.isDisabled}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"基本开户账号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'bankAccount',
                {
                  rules: [
                    { required: true, message: _vm.l('ThisFieldIsRequired') },
                    { max: 128, message: _vm.l('MaxLength') } ],
                } ]),expression:"[\n                'bankAccount',\n                {\n                  rules: [\n                    { required: true, message: l('ThisFieldIsRequired') },\n                    { max: 128, message: l('MaxLength') },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"请输入基本开户账号","allowClear":"","disabled":_vm.isDisabled}})],1)],1)],1),_c('a-row',{staticClass:"form-wrapper",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'address',
                {
                  rules: [
                    { required: true, message: _vm.l('ThisFieldIsRequired') },
                    { max: 128, message: _vm.l('MaxLength') } ],
                } ]),expression:"[\n                'address',\n                {\n                  rules: [\n                    { required: true, message: l('ThisFieldIsRequired') },\n                    { max: 128, message: l('MaxLength') },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"请输入地址","allowClear":"","disabled":_vm.isDisabled}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'telephone',
                {
                  rules: [
                    { required: true, message: _vm.l('ThisFieldIsRequired') },
                    { max: 128, message: _vm.l('MaxLength') } ],
                } ]),expression:"[\n                'telephone',\n                {\n                  rules: [\n                    { required: true, message: l('ThisFieldIsRequired') },\n                    { max: 128, message: l('MaxLength') },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"请输入电话","allowClear":"","disabled":_vm.isDisabled}})],1)],1)],1)],1),(_vm.pageType !== 'preview')?_c('div',{staticClass:"modal-footer"},[_c('a-button',{attrs:{"disabled":_vm.saving,"type":"button"},on:{"click":function($event){return _vm.close()}}},[_c('a-icon',{attrs:{"type":"close-circle"}}),_vm._v(" "+_vm._s(_vm.l("Cancel"))+" ")],1),_c('a-button',{attrs:{"loading":_vm.saving,"type":'primary'},on:{"click":function($event){return _vm.handleSubmit()}}},[_c('a-icon',{attrs:{"type":"save"}}),_vm._v(" "+_vm._s(_vm.l("Save"))+" ")],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }