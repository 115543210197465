<template>
  <!-- <a-spin :spinning="spinning"> -->
  <div>
    <!-- <page-header :title="l('ManageCompany')"></page-header> -->
    <!-- <a-card :bordered="false"> -->
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotal"
      :actions-type="actionsType"
      :scroll="{ x: 1200 }"
      :columns="columns"
      :hideRowSelection="true"
      :current-page="pageNumber"
      :is-full="true"
      :spinning="spinning"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    > -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="true"
      :actionsType="actionsType"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <!-- 按钮 -->
      <a-row :gutter="8">
        <a-col class="gutter-row" :span="4">
          <!-- <a-form-model
            layout="inline"
            :model="searchFormInline"
            @submit="getData"
            @submit.native.prevent
          > -->
          <!-- <a-form-model-item label="客户名称"> -->
          <a-input
            v-model.trim="searchFormInline.name"
            :placeholder="l('请输入客户名称')"
          />
          <!-- </a-form-model-item>
            <a-form-model-item> -->

          <!-- </a-form-model-item> -->
          <!-- </a-form-model> -->
        </a-col>
        <a-col :span="4">
          <a-button
            type="primary"
            @click="search"
            v-if="isGranted('Pages.LGModul.Customer.Query')"
          >
            {{ l("Search") }}
          </a-button>
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
        </a-col>
        <!--
          <a-col class="gutter-row" :span="6">
            <a-button
              type="primary"
              @click="getData"
              v-if="isGranted('Pages.LGModul.Customer.Query')"
            >
              {{ l("Search") }}
            </a-button>
            <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
          </a-col> -->

        <a-col class="gutter-row" style="text-align: right" :span="16">
          <a-button-group>
            <a-button
              type="primary"
              @click="createOrEdit(null, 'create')"
              v-if="isGranted('Pages.LGModul.Customer.Create')"
            >
              <a-icon type="plus" />
              {{ l("Create") }}
            </a-button>
            <a-button
              v-if="isGranted('Pages.LGModul.Customer.Export')"
              @click="exportToExcel()"
            >
              <a-icon type="file-excel" />
              <span>{{ l("Export") }}</span>
            </a-button>
          </a-button-group>
        </a-col>
      </a-row>
    </ele-table>
    <!-- </a-card> -->
    <!-- </a-spin> -->
  </div>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CompanyServiceProxy } from "./services/company-proxies";
import { CommonServiceProxy } from "@/shared/common-service";
import EditForm from "./edit";
import CreateEdit from "./create-edit/create-edit";
import { fileDownloadService } from "@/shared/utils";
import moment from "moment";
import EleTable from "@/components/ele-table";

export default {
  mixins: [AppComponentBase],
  name: "bannerads",
  components: { EleTable },
  data() {
    return {
      spinning: false,
      searchFormInline: {
        name: "",
      },
      // 搜索
      filterText: "",
      // 选择多少项
      selectedRowKeys: [],
      tableData: [],
      tableKey: "CompanyPagedDto",
      // 用户表格
      columns: [
        // {
        //   title: this.l("编码"),
        //   dataIndex: "code",
        //   sorter: true,
        //   align: "center",
        //   width: "120px",
        //   ellipsis: true,
        //   scopedSlots: { customRender: "code" },
        // },
        {
          title: this.l("客户名称"),
          dataIndex: "name",
          sorter: false,
          // align: "center",
          width: 280,
          ellipsis: true,
          scopedSlots: { customRender: "name" },
        },
        {
          title: this.l("简称"),
          dataIndex: "shortName",
          sorter: false,
          align: "center",
          // width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "shortName" },
        },
        // {
        //   title: this.l("简称"),
        //   dataIndex: "shortName",
        //   sorter: true,
        //   align: "center",
        //   scopedSlots: { customRender: "shortName" },
        // },
        {
          title: this.l("客户行业"),
          dataIndex: "industry",
          sorter: false,
          // width: "150px",
          ellipsis: true,
          // align: "center",
          scopedSlots: { customRender: "industry" },
        },
        {
          title: this.l("负责人"),
          dataIndex: "headName",
          sorter: false,
          // width: "120px",
          ellipsis: true,
          // align: "center",
          scopedSlots: { customRender: "headName" },
        },
        {
          title: this.l("手机号"),
          dataIndex: "phone",
          sorter: false,
          // width: 120,
          ellipsis: true,
          // align: "center",
          scopedSlots: { customRender: "phone" },
        },
        {
          title: this.l("地址"),
          dataIndex: "address",
          ellipsis: true,
          // sorter: true,
          // align: "center",
          scopedSlots: { customRender: "address" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          // sorter: true,
          fixed: "right",
          //  align: "center",
          width: 300,
          scopedSlots: { customRender: "actions" },
        },
      ],
      // columns: [],
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: {
        sorting: "",
        maxResultCount: 50,
        skipCount: 0,
      },
      _serviceProxy: "",
      commonService: null,
      actionsType: [],
    };
  },
  created() {
    this._serviceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.getData();
    this.initActionsType();
    // this.initColumns()
  },
  methods: {
    initColumns() {
      let _this = this;
      this.columns = [
        {
          title: this.l("名称"),
          dataIndex: "name",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "name" },
        },
        {
          title: this.l("客户行业"),
          dataIndex: "industry",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "industry" },
        },
        {
          title: this.l("查看详情"),
          dataIndex: "preview",
          sorter: false,
          align: "center",
          customRender: function (text, record, index) {
            let child = _this.$createElement("a", {
              domProps: {
                innerHTML: "查看",
              },
              on: {
                click: function () {
                  _this.createOrEdit(record, "preview");
                },
              },
            });
            let obj = {
              children: child,
              attrs: {},
              on: {
                click: () => {
                  this.$message.info(text);
                },
              },
            };
            return obj;
          },
          scopedSlots: { customRender: "preview" },
        },
        {
          title: this.l("修改"),
          dataIndex: "edit",
          sorter: true,
          align: "center",
          customRender: function (text, record, index) {
            let child = _this.$createElement("a", {
              domProps: {
                innerHTML: "修改",
              },
              on: {
                click: function () {
                  _this.createOrEdit(record, "edit");
                },
              },
            });
            let obj = {
              children: child,
              attrs: {},
              on: {
                click: () => {
                  this.$message.info(text);
                },
              },
            };
            return obj;
          },
          scopedSlots: { customRender: "edit" },
        },
        {
          title: this.l("Action"),
          dataIndex: "actions",
          fixed: "right",
          scopedSlots: { customRender: "actions" },
        },
      ];
    },
    search() {
      this.pageNumber = 1;
      this.request.skipCount = 0;
      this.getData();
    },
    /**
     * 获取数据
     */
    async getData() {
      this.spinning = true;
      try {
        var response = await this._serviceProxy.getCustomerPaged(
          this.searchFormInline.name,
          this.filterText,
          this.request.sorting,
          this.request.maxResultCount,
          this.request.skipCount
        );
        this.spinning = false;
        // console.log(response.items);
        this.tableData = response.items;
        this.totalItems = response.totalCount;
        this.totalPages = Math.ceil(
          response.totalCount / this.request.maxResultCount
        );
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
      } catch (error) {
        this.spinning = false;
      }
    },
    /**
     * table选择事件
     */
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      console.log(this.selectedRows);
    },
    /**
     * 分页事件
     */
    showTotal() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    /**
     * 重置
     */
    refreshGoFirstPage() {
      this.filterText = "";
      this.searchFormInline.name = "";
      this.request.skipCount = 0;
      this.getData();
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
      this.searchFormInline.name = "";
      this.filterText = "";
      this.getData();
    },
    /**
     * 新建修改
     */
    createOrEdit(item, type) {
      ModalHelper.create(
        CreateEdit,
        {
          customer: {
            type: type, // preview, create时不显示tab, edit
            id: item ? item.id : "",
            shortName: item ? item.shortName : "",
          },
        },
        {
          width: "1000px",
          isChange: true,
        }
      ).subscribe((res) => {
        console.log(res);
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },

    /**
     * 清空
     */
    restCheckStatus() {
      this.selectedRowKeys = [];
    },
    /**
     * 单个删除
     */
    deleteItem(id) {
      this.spinning = true;
      this._serviceProxy
        .deleteCustomer(id)
        .finally(() => {
          this.spinning = false;
        })
        .then(() => {
          this.refreshGoFirstPage();
          this.$notification["success"]({
            message: this.l("SuccessfullyDeleted"),
          });
        });
    },
    /**
     * 导出为excel
     */
    exportToExcel() {
      this.spinning = true;
      this._serviceProxy.getToExcelFile().then((result) => {
        this.spinning = false;
        fileDownloadService.downloadTempFile(result);
      });
    },
    // 预览
    previewItem(newV) {
      this.createOrEdit(newV.item, "preview");
    },
    /**
     * emit新建修改
     */
    editItem(newV) {
      // let { id } = newV.item;
      this.createOrEdit(newV.item, "edit");
    },
    /**
     * 排序更新data
     */
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    /**
     * 分页
     */
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      console.log(newV);
      let { curPage, maxResultCount } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = maxResultCount;
      this.getData();
    },
    /**
     * table选择事件
     */
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },

    /**
     * 初始化需要显示的func btn
     */
    initActionsType() {
      let _this = this;
      let obj = {
        type: "project",
        isShow: true,
        fns: {
          isShow: true,
          dropList: [
           
            // {
            //   delete: true,
            //   granted: this.isGranted("Pages.LGModul.Customer.Delete"),
            //   name: this.l("Delete"),
            //   icon: "delete",
            //   fn: (data) => {
            //     _this.deleteItem(data.id);
            //   },
            // },
          ],
          detailList: [
             {
              granted: this.isGranted("Pages.LGModul.Customer.Edit"),
              name: this.l("Edit"),
              icon: "edit",
              fn: (data) => {
                _this.createOrEdit(data, "edit");
              },
            },
            {
              granted: this.isGranted("Pages.LGModul.Customer.View"),
              name: this.l("查看"),
              icon: "setting",
              with: "50%",
              fn: (data) => {
                _this.createOrEdit(data, "view");
              },
            },
            {
              granted: this.isGranted("Pages.LGModul.Customer.Detail"),
              name: this.l("客户配置"),
              icon: "setting",
              with: "50%",
              fn: (data) => {
                _this.createOrEdit(data, "preview");
              },
            },
          ],
          delete: {
            granted: this.isGranted("Pages.LGModul.Customer.Delete"),
            name: this.l("Delete"),
            // width: "50%",
            fn: (data) => {
              _this.deleteItem(data.id);
            },
          },
        },
      };
      this.actionsType = obj;
    },
  },
};
</script>

<style lang="less" scoped>
.pagination {
  margin: 10px auto;
  text-align: right;
}

.table-plus,
.table-delete {
  margin-left: 10px;
}
</style>
