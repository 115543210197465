<template>
  <a-spin :spinning="spinning">
    <!-- table -->
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :tableKey="tableKey"
      :showTotal="showTotalFun"
      :scroll="{ x: 1500 }"
      :columns="columns"
      :hide-row-selection="true"
      :current-page="pageNumber"
      :actions-type="actionsType"
      @emitRefreshData="getData"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    > -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="false"
      :actionsType="actionsType"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitRefreshData="getData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <!-- 操作 -->
      <a-row :gutter="8" type="flex" align="middle">
        <a-col :span="8">
          <a-input-search
            name="filterText"
            @search="getData(reqUrl.getPaged, id)"
            :placeholder="l('SearchWithThreeDot')"
            enterButton
            v-model="filterText"
          />
        </a-col>
        <a-col :span="16" style="text-align: right" v-if="type != 'view'">
          <a @click="createOrEdit(null, 'create')">
            <a-icon type="plus" /> {{ l("添加发票") }}
          </a>
          <a-divider type="vertical"></a-divider>
          <a :title="l('Refresh')" @click="clearFilterAndRefresh()">
            <a-icon type="reload"
          /></a>
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { CustomerBaseInvoiceServiceProxy } from "./services/customer-baseinvoice-proxies";
import { ModalHelper } from "@/shared/helpers";
import Bus from "@/shared/bus/bus";
import EleTable from "@/components/ele-table";
import OperateCustomerBaseinvoiceComponent from "./operate-customer-baseinvoice/operate-customer-baseinvoice";

export default {
  name: "customer-baseinvoice",
  mixins: [AppComponentBase],
  props: ["id", "reqUrl", "tableKey", "type"],
  components: { EleTable },
  data() {
    return {
      spinning: false,
      _serviceProxy: null,
      selectedRowKeys: [],
      filteredInfo: null,
      sortedInfo: null,
      name: "",
      organizationUnitId: "",
      filterText: "",
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 50, skipCount: 0 },
      // 用户表格
      columns: [
        {
          title: this.l("发票抬头"),
          dataIndex: "title",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "title" },
        },
        // {
        //   title: this.l("发票类型"),
        //   dataIndex: "type",
        //   sorter: true,
        //   align: "center",
        //   scopedSlots: { customRender: "type" },
        // },
        {
          title: this.l("纳税人识别码"),
          dataIndex: "number",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "number" },
        },
        {
          title: this.l("基本开户银行"),
          dataIndex: "bankName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "bankName" },
        },
        {
          title: this.l("基本开户账号"),
          dataIndex: "bankAccount",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "bankAccount" },
        },
        {
          title: this.l("地址"),
          dataIndex: "address",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "address" },
        },
        {
          title: this.l("电话"),
          dataIndex: "telephone",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "telephone" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: false,
          align: "center",
          fixed: "right",
          scopedSlots: { customRender: "actions" },
        },
      ],
      // 用户数据
      tableData: [],
      actionsType: {},
      idKey: "", // 调用getPaged时 id的key
    };
  },
  computed: {},
  created() {
    this._serviceProxy = new CustomerBaseInvoiceServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this.initActionsType();
    this.idKey =
      this.tableKey === "CustomerSealPagedDto" ||
      this.tableKey === "CustomerBankAccountPagedDto" ||
      this.tableKey === "CustomerBaseInvoicePagedDto"
        ? "customerId"
        : "organizationUnitId";
  },
  beforeDestroy() {},
  methods: {
    // 拉取数据
    async getData(url, id) {
      this.spinning = true;
      let res = await this._serviceProxy.getAccountPaged(
        url ? url : this.reqUrl.getPaged,
        this.name,
        this.idKey,
        id ? id : this.id,
        this.filterText,
        this.request.sorting,
        this.request.maxResultCount,
        this.request.skipCount
      );
      this.spinning = false;
      this.tableData = res.items.map((o) => {
        o.type = ["增值税普通发票", "增值税专用发票"][o.type-1];
        return {
          ...o,
        };
      });
      this.totalItems = res.totalCount;
      this.pagerange = [
        (this.pageNumber - 1) * this.request.maxResultCount + 1,
        this.pageNumber * this.request.maxResultCount,
      ];
      this.totalPages = Math.ceil(res.totalCount / this.request.maxResultCount);
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
      this.name = "";
      this.filterText = "";
      this.getData(this.reqUrl.getPaged);
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    // 删除
    async deleteItem(item) {
      let { id } = item;
      this.spinning = true;
      let res = await this._serviceProxy.deleteAccount(id, this.reqUrl.delete);
      this.spinning = false;
      this.getData(this.reqUrl.getPaged);
      this.$notification["success"]({
        message: this.l("SuccessfullyRemoved"),
      });
      this.clearFilterAndRefresh();
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData(this.reqUrl.getPaged);
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData(this.reqUrl.getPaged);
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData(this.reqUrl.getPaged);
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "seal",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: true,
              name: this.l("查看"),
              icon: "edit",
              fn: (data) => {
                _this.createOrEdit(data, "preview");
              },
            },
            {
              granted: this.type != "view",
              name: this.l("修改"),
              icon: "edit",
              fn: (data) => {
                // if (this.type === "view") {
                //   return;
                // }
                _this.createOrEdit(data, "edit");
              },
            },
          ],
          delete: {
            granted: this.isGranted("Pages.Customer.Delete"),
            name: this.l("Delete"),
            fn: (data) => {
              _this.deleteItem(data);
            },
          },
        },
      };
      this.actionsType = obj;
    },
    // 增加修改查看
    createOrEdit(item, type) {
      // 因为Organization和Customer的编辑页面只有保存时id不一样，暂时不区分两个页面
      // let comp =
      //   this.tableKey === "CustomerSealPagedDto"
      //     ? AddAccountCustomerComponent
      //     : AddAccountOrganizeComponent;
      ModalHelper.create(
        OperateCustomerBaseinvoiceComponent,
        {
          organizationUnit: {
            type: type, // edit,preview,create
            parentId: this.id,
            id: item ? item.id : null,
            reqUrl: this.reqUrl,
          },
        },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.user-container {
  border: 1px solid #e8e8e8;
  margin: 20px;
  padding: 20px;
  .table-edit,
  .table-delete {
    margin-left: 3px;
    i {
      padding: 0 2px;
    }
  }
}
.opeattion-container {
  margin: 20px 0;
}
.pagination {
  margin: 10px auto;
  text-align: right;
}
</style>
