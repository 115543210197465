var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{attrs:{"label-col":{ span: 6 },"wrapper-col":{ span: 18 },"form":_vm.form}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.l('客户名称')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'name',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":_vm.l('请输入')}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.l('客户简称')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'shortName',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'shortName',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":_vm.l('请输入')}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.l('客户行业')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'industry',
              {
                rules: [
                  {
                    required: false,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'industry',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":_vm.l('请输入')}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.l('客户编码')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'code',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'code',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":_vm.l('请输入')}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.l('负责人')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'headName',
              {
                rules: [
                  {
                    required: false,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'headName',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":_vm.l('请输入')}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.l('手机号')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'phone',
              {
                rules: [
                  {
                    required: false,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'phone',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":_vm.l('请输入')}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.l('电子邮箱')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'email',
              {
                rules: [
                  {
                    required: false,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'email',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":_vm.l('请输入')}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.l('座机号')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'seatNumber',
              {
                rules: [
                  {
                    required: false,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'seatNumber',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":_vm.l('请输入')}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.l('传真号')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'faxNumber',
              {
                rules: [
                  {
                    required: false,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'faxNumber',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":_vm.l('请输入')}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.l('通讯地址')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'address',
              {
                rules: [
                  {
                    required: false,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'address',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":_vm.l('请输入')}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.l('邮政编码')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'postalCode',
              {
                rules: [
                  {
                    required: false,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'postalCode',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":_vm.l('请输入')}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.l('备注')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'remark',
              {
                rules: [
                  {
                    required: false,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'remark',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":_vm.l('请输入')}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.l('附件资源')}},[_c('a-upload',{attrs:{"action":_vm.uploadUrl,"multiple":true,"data":{ subdir: this.subdirName },"file-list":_vm.fileList,"showUploadList":{
              showPreviewIcon: true,
              showRemoveIcon: !_vm.isDisabled,
            }},on:{"change":_vm.handleUploadChange}},[(!_vm.isDisabled)?_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传附件 ")],1):_vm._e()],1)],1)],1)],1)],1),(!_vm.isDisabled)?_c('div',{staticClass:"modal-footer"},[_c('a-button',{attrs:{"disabled":_vm.saving,"type":"button"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.l("Cancel"))+" ")]),_c('a-button',{attrs:{"disabled":_vm.saving,"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.l("Save"))+" ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }